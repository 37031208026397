/* istanbul ignore file */
// this file uses webpack specific require.context and it fails in jest

import { MODULE_NAME as EMPLOYEE_MODULE_NAME } from '@/app/modules/employee/employee.module'
import { MODULE_NAME as SETTINGS_MODULE_NAME } from '@/app/modules/settings/settings.module'

// some modules or parts of them are required globally. So we install them
// regardless of user having feature enabled or not
const ESSENTIAL_MODULES = {
  [EMPLOYEE_MODULE_NAME]: { store: true, routes: true },
  [SETTINGS_MODULE_NAME]: { routes: true },
}

const cachedModules = {}

// synchronously import all *.module.js files and cache them in `cachedModules`
const requireModule = require.context('.', true, /\.module.(ts|js)$/)
requireModule.keys().forEach((path) => {
  const m = requireModule(path)
  cachedModules[m.MODULE_NAME] = m
})

export const MODULE_NAME_LIST = Object.keys(cachedModules)

// install user features/modules
export function installModules(modules) {
  if (!modules) {
    return Promise.resolve()
  }
  const promises = []

  for (const moduleName of MODULE_NAME_LIST) {
    if (modules[moduleName]) {
      // install user feature module
      promises.push(cachedModules[moduleName].install())
    } else if (ESSENTIAL_MODULES[moduleName]) {
      // install essential module
      promises.push(
        cachedModules[moduleName].install(ESSENTIAL_MODULES[moduleName])
      )
    }
  }

  return Promise.all(promises)
}

// uninstall all modules
export function uninstallAllModules() {
  for (const moduleName of MODULE_NAME_LIST) {
    cachedModules[moduleName]?.uninstall()
  }
}
